import {ICoreContext} from '@msdyn365-commerce/core';
import {CommerceProperty} from '@msdyn365-commerce/retail-proxy';
import {IFinancingStaticDataConfig} from '../../../modules/financing-static-data/financing-static-data.props.autogenerated';
// import { Address } from '@msdyn365-commerce/retail-proxy';

export interface IOwnerDataProps {
    FirstName_CAP?: string;
    LastName_CAP?: string;
    FiscalCode_CAP?: string;
    Citizenship_CAP?: string;
    Birthday_CAP?: string;
    BirthPlace_CAP?: string;
    OrgName_CAP?: string;
    VatNum_CAP?: string;
    Type_CAP?: string;
    ZipCode_CAP?: string;
    Address_CAP?: string;
    City_CAP?: string;
    Country_CAP?:string;
    DeliveryMode_CAP?:string;
    Phone_CAP?:string;
}
export interface IFinancingProps {
    context: ICoreContext;
    transactionId?:string;
    productExtensionProperties?:CommerceProperty[];
    financingStaticTexts?: IFinancingStaticDataConfig;
    finalPrice?:number;
    itemId?:string;
    ownerData?: {
        FiscalCode_CAP?:string;
        CitezenShip_CAP?:string;
        BirthDay_CAP?:string;
        BirthPlace_CAP?:string;
        Orgname_CAP?:string;
        VatNum_CAP?:string;
        Type_CAP?:string;
        Phone_CAP?:string;
        Address_CAP?:string;
        ZipCode_CAP?:string;
        Country_CAP?:string;
        City_CAP?:string;
    };
    changeFinancingStatus?:(i:boolean) => void;
    changeSimulationView?:(i:boolean) => void;
    itemConfiguration: string | undefined | false;
}
export const productExtProps = (productExtensionProperties:CommerceProperty[] | undefined) => {
    const durationMax       = productExtensionProperties?.filter(i => i.Key === 'NumOfMonthSantander')[0].Value?.IntegerValue;
    const tan               = productExtensionProperties?.filter(i => i.Key === 'TanSantander')[0].Value?.DecimalValue;
    const taeg              = productExtensionProperties?.filter(i => i.Key === 'TaegSantander')[0].Value?.DecimalValue;
    const fromAmountSA      = productExtensionProperties?.filter(i => i.Key === 'FromAmountSantander')[0].Value?.DecimalValue;
    const confirmDepositAmt = productExtensionProperties?.filter(i => i.Key === 'ConfirmDepositAmt')[0]?.Value?.DecimalValue;
    const loanType          = productExtensionProperties?.filter(i => i.Key === 'LoanType')[0]?.Value?.StringValue;
    const goodType          = productExtensionProperties?.filter(i => i.Key === 'GoodType')[0]?.Value?.StringValue;
    const legalNotice       = productExtensionProperties?.filter(i => i.Key === 'LegalNotice')[0]?.Value?.StringValue;
    return {
        durationMax,
        tan,
        taeg,
        fromAmountSA,
        confirmDepositAmt,
        loanType,
        goodType,
        legalNotice
    };
};

export const financingTexts = (props:IFinancingProps)  => {
    const { financingStaticTexts }  = props;
    const fTexts                    = financingStaticTexts;
    const advanceDesc               = fTexts?.fAdvanceDesc;
    const baloonDesc                = fTexts?.fBaloonDesc;
    const baloonTitle               = fTexts?.fBaloonTitle;
    const standardDesc              = fTexts?.fStandardDesc;
    const standardTitle             = fTexts?.fStandardTitle;
    const introText                 = fTexts?.introText;
    const standardTooltip           = fTexts?.fStandardTooltip;
    const baloonTooltip             = fTexts?.fBaloonTooltip;
    const tanTooltip                = fTexts?.fTanTooltip;
    const taegTooltip               = fTexts?.fTaegTooltip;
    const text = {
        advanceDesc,
        baloonDesc,
        baloonTitle,
        standardDesc,
        standardTitle,
        introText,
        standardTooltip,
        baloonTooltip,
        tanTooltip,
        taegTooltip
    }
    return { text };
};