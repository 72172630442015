import React from 'react';
import { Controller, useForm } from 'react-hook-form';

import { ISliderProps, Slider } from '@msdyn365-commerce-modules/utilities';
import { RichTextComponent } from '@msdyn365-commerce/core';
import { euroTax_CAPAsync } from '../../../../actions/CapServices_DataActionExtension.g'
import { getCOValue_CAPAsync, getLoanLenghtValue_CAPAsync } from '../../../../actions/Santander_DataActionExtension.g';
import { financingTexts, IFinancingProps, productExtProps } from '../../utils/utils';

import NumberFormat from 'react-number-format';
// import useCurrencyMask from '../../utils/useCurencyMask';

type financingInputs = {
    loanTypeFromUser:   string | null;
    advanceFromUser:    number | null;
    durationFromUser:   string | null;
};

// tslint:disable-next-line:cyclomatic-complexity
export const Financing = (props:IFinancingProps) => {
    const isBrowser = typeof window !== 'undefined' && localStorage !== undefined;
    if(!isBrowser){
        return <></>;
    }
    /* Toggle the financing area */
    const [financingToggle, setFinancingToggle] = React.useState(localStorage.getItem('toggleState') === 'opened' ? true : false);
    const handleToggle = () => {
        setFinancingToggle(!financingToggle);
        !financingToggle ? localStorage.setItem('toggleState', 'opened') : localStorage.setItem('toggleState', 'closed');
    };

    /* Data */
    const { text }                  = financingTexts(props);
    const { confirmDepositAmt }     = productExtProps(props.productExtensionProperties);
    const { itemId, finalPrice, itemConfiguration }    = props;
    const amountToBeFinanced        = (finalPrice as number) - (confirmDepositAmt as number);
    /* React Form */
    const loanTypeAutoSelect        = localStorage.getItem('loanType') ? localStorage.getItem('loanType') : 'Classic';
    const anticipoFromUserString    = Number(localStorage.getItem('advance'));
    const {
        register,
        handleSubmit,
        watch,
        control,
        errors
    }                               = useForm<financingInputs>({defaultValues: {loanTypeFromUser: loanTypeAutoSelect, advanceFromUser: anticipoFromUserString ? Number(anticipoFromUserString) : 1000}});
    const loanType                  = watch('loanTypeFromUser');
    let anticipo                    = watch('advanceFromUser');
    const opt                       = loanType === 'Classic' ? 1 : 2;
    const importo                   = anticipo ? (amountToBeFinanced - anticipo).toFixed(2) : amountToBeFinanced.toFixed(2);
    /* Durations */
    const [currentDuration, setCurrentDuration] = React.useState(document.getElementById('firstThumbValuerange')?.getAttribute('aria-valuenow'));
    const getCurrentDuration = () => {
        setCurrentDuration(document.getElementById('firstThumbValuerange')?.getAttribute('aria-valuenow'));
    }
    const [duration, setDuration]               = React.useState({min:0, max:0, steps:1});
    let lastDuration                            = localStorage.getItem('duration') && opt === 1 ? parseInt(localStorage.getItem('duration') as string, 10) : duration.min;
    // opt === 1 ? lastDuration : duration.min;
    // tslint:disable-next-line:no-shadowed-variable
    const _durationValues           = async(opt:number) => {
        getLoanLenghtValue_CAPAsync(
            {callerContext: props?.context?.actionContext},
            itemId as string,
            opt,
            'P'
        ).then((result) => {
            const durations         = result.Lenghts?.split(',');
            if (durations) {
                setDuration({
                    min:    parseInt(durations[0], 10),
                    max:    parseInt(durations[durations.length - 1], 10),
                    steps:  (parseInt(durations[durations.length - 1], 10) - parseInt(durations[0], 10)) / (durations.length - 1)
                });
            }
        });
    };
    

    
    /* Slider */
    const sliderProps: Partial<ISliderProps> = {
        inForm:             true,
        min:                duration.min,
        max:                duration.max,
        orientation:        'horizontal',
        showProgressBar:    false,
        showLabels:         true,
        showTooltip:        true,
        step:               duration.steps,
        labels: [
            {
                labelId:            '',
                labelString:        `${duration.min}`,
                labelPositioning:   'start'
            },
            {
                labelId:            '',
                labelString:        `${duration.max}`,
                labelPositioning:   'end'
            },
        ],
        sliderThumbs: [
            {
                id:     'firstThumbValue',
                value:  currentDuration ? parseInt(currentDuration as string, 10) : lastDuration
            },
        ],
    };

    /* Handle change of loan type */
    // tslint:disable-next-line:no-shadowed-variable
    const handleOpt = (opt:number) => {
        void _durationValues(opt);
        if (opt === 2 && sliderProps && sliderProps.sliderThumbs) {
            sliderProps.sliderThumbs[0].value = duration.min;
        }
        opt === 1 ? localStorage.setItem('loanType', 'Classic') : localStorage.setItem('loanType', 'Flex');
        if (document.querySelector('.financing-container.toggled')?.classList.contains('updated')) {
            document.querySelector('.financing-container.toggled')?.classList.remove('updated');
            setShowSimulation(!showSimulation);
        }
    };

    /* Show Simulation */
    const [showSimulation, setShowSimulation]   = React.useState(false);
    const [errorCOValue, setErrorCOValue]       = React.useState(false);
    const [saResultData, setSaResultData]       = React.useState({
        Tan         : '',
        Taeg        : '',
        MonthlyRate : '',
        MaxiRate    : '',
        AmountDue   : '',
        Interests   : '',
        Flex: {
            duration                : 0,
            durationTotal           : 0,
            monthlyRate             : 0,
            tan                     : 0,
            taeg                    : 0,
            secondPartFirstMonth    : 0,
            maxiRateFinal           : 0,
            monthlyRateSecondPart   : 0,
            tanSecondPart           : 0,
            taegSecondPart          : 0,
        }
    });

    /* Manage 24h countdown to emptying localStorage */
    const now    = new Date();
    const expiry = now.getTime() + 86400000;
    if (localStorage.getItem('expiry') && now.getTime() > Number(localStorage.getItem('expiry'))) {
        localStorage.clear();
    }
    const [legalNotes, setLegalNotes] = React.useState('');
    let length = 0;
    
    const[loading, setLoading] = React.useState(false);
    props.changeFinancingStatus && props.changeFinancingStatus(financingToggle);
    props.changeSimulationView && props.changeSimulationView(showSimulation);

    /* Submit to simulation, to localStorage && show simulation */
    const handleSubmitFinancing = (data:financingInputs) => {
        setLoading(true);

        if (duration.min !== duration.max && data.durationFromUser) {
            localStorage.setItem('duration', Object.entries(data.durationFromUser).filter(([key]) => key === 'firstThumbValue')[0][1]);
            length = parseInt(Object.entries(data.durationFromUser).filter(([key]) => key === 'firstThumbValue')[0][1], 10);
        } else if (duration.min === duration.max) {
            localStorage.setItem('duration', JSON.stringify(duration.min));
            length = duration.min;
        } else if (opt === 1 && !data.durationFromUser && lastDuration.toString() !== localStorage.getItem('duration')) {
            localStorage.setItem('duration', JSON.stringify(duration.min));
            length = duration.min;
        } else if (opt === 1 && !data.durationFromUser && lastDuration.toString() === localStorage.getItem('duration')) {
            length = parseInt(localStorage.getItem('duration') as string, 10);
        }

        euroTax_CAPAsync(
            {callerContext: props?.context?.actionContext},
            itemId as string,
            itemConfiguration ? itemConfiguration : ''
            ).then((euroTax) => {
                getCOValue_CAPAsync(
                {callerContext: props?.context?.actionContext},
                itemId as string,
                opt,
                'P',
                '', // orderID on orderdetails, unused elsewhere
                length,
                Number(importo),
                finalPrice as number,
                euroTax.EuroTaxCode as string
            ).then((result) => {
                setShowSimulation(true);
                if (result && result.VarRates && result.VarRates.length === 0) {
                    setSaResultData({
                        Tan:            result.Tan          as string,
                        Taeg:           result.Taeg         as string,
                        MonthlyRate:    result.MonthlyRate  as string,
                        MaxiRate:       result.MaxiRate     as string,
                        AmountDue:      result.AmountDue    as string,
                        Interests:      result.Interests    as string,
                        Flex: {
                            duration:               0,
                            durationTotal:          0,
                            monthlyRate:            0,
                            tan:                    0,
                            taeg:                   0,
                            secondPartFirstMonth:   0,
                            maxiRateFinal:          0,
                            monthlyRateSecondPart:  0,
                            tanSecondPart:          0,
                            taegSecondPart:         0,
                        },
                    });
                    localStorage.setItem('tan',     result.Tan  as string);
                    localStorage.setItem('taeg',    result.Taeg as string);
                } else if (result && result.VarRates && result.VarRates.length > 0) {
                    setSaResultData({
                        Tan:            result.Tan          as string,
                        Taeg:           result.Taeg         as string,
                        MonthlyRate:    result.MonthlyRate  as string,
                        MaxiRate:       result.MaxiRate     as string,
                        AmountDue:      result.AmountDue    as string,
                        Interests:      result.Interests    as string,
                        Flex: {
                            duration:               result.VarRates[0].ARata,
                            durationTotal:          result.VarRates[1].ARata,
                            monthlyRate:            result.VarRates[0].ImportoRata,
                            tan:                    result.VarRates[0].Tan,
                            taeg:                   result.VarRates[0].Taeg,
                            secondPartFirstMonth:   result.VarRates[1].DaRata,
                            maxiRateFinal:          result.VarRates[0].CapitaleResiduo,
                            monthlyRateSecondPart:  result.VarRates[1].ImportoRata,
                            tanSecondPart:          result.VarRates[1].Tan,
                            taegSecondPart:         result.VarRates[1].Taeg,
                        },
                    });
                    localStorage.setItem('tan',             result.VarRates[0].Tan.toString());
                    localStorage.setItem('taeg',            result.VarRates[0].Taeg.toString());
                    localStorage.setItem('max-rata-finale', result?.VarRates[0]?.CapitaleResiduo.toString())
                }
                anticipo = data.advanceFromUser;
                localStorage.setItem('importo',             importo);
                localStorage.setItem('expiry',              expiry.toString());
                localStorage.setItem('quotaNum',            result.QuotationNumber.toString());
                localStorage.setItem('productId',           itemId                  as string);
                localStorage.setItem('advance',             String(data.advanceFromUser));
                localStorage.setItem('interests',           result.Interests        as string);
                localStorage.setItem('rata-mensile',        result.MonthlyRate      as string);
                localStorage.setItem('secciUrl',            result.SecciUrl         as string);
                localStorage.setItem('finalPrice',          finalPrice?.toString()  as string);
                localStorage.setItem('confirmDepositAmt',   confirmDepositAmt?.toString() as string);
                localStorage.setItem('amount-due-total',    result.AmountDue        as string);
                localStorage.setItem('interests',           result.Interests        as string);
                setLegalNotes(result.LegalNotes as string);
                opt === 1 ? localStorage.setItem('loanType', 'Classic') : localStorage.setItem('loanType', 'Flex');
                document.querySelector('.financing-container.toggled')?.classList.add('updated');
                setErrorCOValue(false);
                setLoading(false);
            }).catch( err => {
                    setErrorCOValue(true);
                    setShowSimulation(false);
                    setLoading(false);
                });
        });
    };

    setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
    }, 300);

    React.useEffect(() => {
        getCurrentDuration();
        void _durationValues(opt);
    }, [opt, lastDuration]);

    return (
        // tslint:disable-next-line:max-func-body-length
        <div id='financing-container' className={financingToggle ? 'financing-container toggled ' : 'financing-container '}>
            <div className='financing-container__header'>
                <div className='financing-container__instructions'>
                    <RichTextComponent
                        text={text.introText}
                    />
                </div>
                <button
                    className='toggler-container'
                    role='checkbox'
                    aria-checked={financingToggle}
                    onChange={handleToggle}
                >
                    <span className={financingToggle ? 'active' : ''}>ON</span>
                    <div className='toggler'>
                        <input
                            type='checkbox'
                            id='toggle'
                            name='toggle'
                            aria-checked={financingToggle}
                            checked={financingToggle}
                        />
                        <label id='control' htmlFor='toggle'/>
                    </div>
                    <span>OFF</span>
                </button>
            </div>
            {financingToggle ? (
                <div className='financing-toggeable'>
                    <form className='financing-options' onSubmit={handleSubmit(handleSubmitFinancing)}>
                        <fieldset>
                            <legend className='sr-only'>Tipo di finanziamento</legend>
                            <div className='financing-options__field'>
                                <input ref={register} name={'loanTypeFromUser'} type='radio' value='Classic' aria-checked={false} onChange={() => handleOpt(1)}/>
                                <div className='financing-table__heading'>
                                    <label className='financing-table__heading-title' htmlFor='Classic'>
                                        {text.standardTitle}
                                    </label>
                                    <p className='sub-label'>{text.standardDesc}</p>
                                </div>
                                <div className='icon-tooltip'>
                                    <span aria-hidden='true' className='icon icon-info'/>
                                    <div className='tooltip'>
                                        <p>{text.standardTooltip}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='financing-options__field'>
                                <input ref={register} name={'loanTypeFromUser'} type='radio' value='Flex' aria-checked={false} onChange={() => handleOpt(2)}/>
                                <div className='financing-table__heading'>
                                    <label className='financing-table__heading-title' htmlFor='Flex'>{text.baloonTitle}</label>
                                    <p className='sub-label'>{text.baloonDesc}</p>
                                </div>
                                <div className='icon-tooltip'>
                                    <span aria-hidden='true' className='icon icon-info'/>
                                    <div className='tooltip'>
                                        <p>{text.baloonTooltip}</p>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        <div className='financing-option active'>
                            {showSimulation ? (
                                <div className='financing-legals'>
                                    <div className='text-tooltip'>
                                        <p>Prendi visione delle{' '}
                                            <span className='text'>Note legali</span>
                                            <div className='tooltip'><p>{legalNotes}</p></div>
                                            {' '}prima di proseguire
                                        </p>
                                    </div>
                                </div>
                                ) : ''
                            }
                            <div className='financing-amount'>
                                <p>Importo da finanziare</p>
                                <span>€ {importo && parseFloat(importo) >= 0 ? Number(importo).toLocaleString('it-IT') : Number(amountToBeFinanced.toFixed(2)).toLocaleString('it-IT')}</span>
                            </div>
                            <div className='financing-contribution'>
                                <div>
                                    <span>Anticipo</span>
                                    <p>{text.advanceDesc}</p>
                                </div>
                                <div className='input__currency-prefixed'>
                                    <div aria-invalid={errors.advanceFromUser ? true : false}>
                                        <Controller
                                            render={({ onChange, value }) => (
                                                <NumberFormat
                                                    allowNegative={false}
                                                    decimalSeparator={','}
                                                    decimalScale={2}
                                                    onValueChange={(v) => onChange(v.floatValue)}
                                                    // prefix="€"
                                                    value={value}
                                                    thousandSeparator={'.'}
                                                    fixedDecimalScale={true}
                                                />
                                            )}
                                            defaultValue={1000}
                                            name={'advanceFromUser'}
                                            control={control}
                                            rules={{ min: 0, max: amountToBeFinanced }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='financing-duration'>
                                <div>
                                    <p>Durata finanziamento
                                        {duration.min !== duration.max ? (` (${currentDuration ? currentDuration : lastDuration} MESI)`)
                                        : ` (${duration.min} MESI)`
                                        }
                                    </p>
                                </div>
                                    {duration.min === duration.max ? (
                                        <div className='financing-duration__single-container'>
                                            <span className='financing-duration__single-value'>{duration.min}</span>
                                        </div>
                                    ) : (
                                        <div className='financing-duration__slider-container'>
                                            <Controller
                                                name='durationFromUser'
                                                control={control}
                                                render={(field) => <Slider {...sliderProps} {...field} onChangeEnd={() => getCurrentDuration()} />}
                                            />
                                        </div>
                                    )}
                            </div>
                            <div className='financing-cta'>
                                <button type='submit'>SCOPRI IL FINANZIAMENTO</button>
                            </div>
                        </div>
                        <button type='submit' title='Update' className='ms-checkout__guided-card-btn-redo msc-btn outlined-button'>Modifica il finanziamento</button>
                        <div className={`loader__ctn ${loading ? 'is--loading' : ''}`}>
                            <div className={`loader ${loading ? 'is--loading' : ''}`}></div>
                        </div>
                        {errorCOValue && (
                            <div className={'financing-simulation_error'}>
                                Non ci sono soluzioni disponibili per il finanziamento richiesto, modifica la durata e/o  l’importo e scopri il nuovo finanziamento
                            </div>
                        )}
                        {showSimulation ? (
                            <div className='financing-simulation'>
                                <div className='financing-simulation__table'>
                                    <div className='financing-simulation__table-head'>
                                        <p>Il tuo finanziamento</p>
                                        <p>Simulazione non vincolante</p>
                                    </div>
                                    <ul>
                                        <li>
                                            <span className='simulation-value'>{anticipo ? Number(anticipo).toLocaleString('it-IT') : 0}€</span>
                                            <span className='simulation-label'>Anticipo</span>
                                        </li>
                                        <li>
                                            <span className='simulation-value'>{opt === 1 ? localStorage.getItem('duration') : saResultData && saResultData.Flex.duration} MESI</span>
                                            <span className='simulation-label'>{opt === 1 ? 'Durata del finanziamento' : 'Durata primo periodo'}</span>
                                        </li>
                                        <li>
                                            <span className='simulation-value'>{saResultData && Number(saResultData.MonthlyRate).toLocaleString('it-IT')}€</span>
                                            <span className='simulation-label'>Rata mensile</span>
                                        </li>
                                        <li>
                                            <span className='simulation-value'>{opt === 1 ? saResultData && Number(saResultData.Tan).toLocaleString('it-IT') : saResultData && Number(saResultData.Flex.tan).toLocaleString('it-IT')}%</span>
                                            <span className='simulation-label'>
                                                <div className='icon-tooltip'>
                                                    <span aria-hidden='true' className='icon icon-info'/>
                                                    <div className='tooltip'>
                                                        <p>{text.tanTooltip}</p>
                                                    </div>
                                                </div>
                                                <span>Tan</span>
                                            </span>
                                        </li>
                                        <li style={opt === 1 ? {borderBottomColor: '#000000'} : {}}>
                                            <span className='simulation-value'>{opt === 1 ? saResultData && Number(saResultData.Taeg).toLocaleString('it-IT') : saResultData && Number(saResultData.Flex.taeg).toLocaleString('it-IT')}%</span>
                                            <span className='simulation-label'>
                                                <div className='icon-tooltip'>
                                                    <span aria-hidden='true' className='icon icon-info'/>
                                                    <div className='tooltip'>
                                                        <p>{text.taegTooltip}</p>
                                                    </div>
                                                </div>
                                                <span>Taeg</span>
                                            </span>
                                        </li>
                                        {opt === 2 ? (
                                            <>
                                                <li className='financing-simulation__maxirata'>
                                                    <div>
                                                        <span className='simulation-value'>{saResultData && Number(saResultData.Flex.maxiRateFinal).toLocaleString('it-IT')}€ </span>
                                                        <span className='simulation-label'>
                                                            Maxirata finale (<span className='bold'>{saResultData && saResultData.Flex.secondPartFirstMonth}° Rata</span>)
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <p>Oppure potrai scegliere di estinguere l'importo della maxi rata finale tramite finanziamento di:</p>
                                                        <p>
                                                            <span className='bold'>{saResultData && Number(saResultData.Flex.monthlyRateSecondPart).toLocaleString('it-IT')}€</span>
                                                            {' '}da{' '}
                                                            <span className='bold'>{saResultData && saResultData.Flex.secondPartFirstMonth}°</span>
                                                            {' '}mese a{' '}
                                                            <span className='bold'>{saResultData && saResultData.Flex.durationTotal}°</span>
                                                            {' '}mese
                                                            <span className='financing-simulation__maxirata-tan'>
                                                                TAN<span>{saResultData && Number(saResultData.Flex.tanSecondPart).toLocaleString('it-IT')}%</span>
                                                                <div className='icon-tooltip icon-tooltip__small'>
                                                                    <span aria-hidden='true' className='icon icon-info'/>
                                                                    <div className='tooltip'>
                                                                        <p>{text.tanTooltip}</p>
                                                                    </div>
                                                                </div>
                                                            </span>
                                                            <span className='financing-simulation__maxirata-taeg'>
                                                                TAEG<span>{saResultData && Number(saResultData.Flex.taegSecondPart).toLocaleString('it-IT')}%</span>
                                                                <div className='icon-tooltip icon-tooltip__small'>
                                                                    <span aria-hidden='true' className='icon icon-info'/>
                                                                    <div className='tooltip'>
                                                                        <p>{text.taegTooltip}</p>
                                                                    </div>
                                                                </div>
                                                            </span>
                                                        </p>
                                                    </div>
                                                </li>
                                            </>
                                        ) : ''
                                        }
                                        <li>
                                            <span className='simulation-value'>{saResultData && Number(saResultData.AmountDue).toLocaleString('it-IT')}€</span>
                                            <span className='simulation-label'>Importo totale dovuto</span>
                                        </li>
                                        <li>
                                            <span className='simulation-value'>{saResultData && Number(saResultData.Interests).toLocaleString('it-IT')}€</span>
                                            <span className='simulation-label'>Interessi</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        ) : ''
                        }
                    </form>
                </div>
            ) : ''
            }
        </div>
    );
};
